// Desktop
@media (min-width: 1000px) {
  .Split {
    text-align: center;
    clear: both;
    width: 52%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    margin-top: 50px;
    min-height: 100px;
    display: flex;
    flex-direction: row;

    .splitLeft {
      width: 50%;
    }

    .splitRight {
      border-left: 1px solid rgb(87, 154, 173);
      width: 50%;
    }
  }
}


// Mobile
@media (max-width: 1000px) {



}