// Desktop
@media (min-width: 1000px) {
  #faq {
    text-align: center;

    .faqItem {
      width: 50%;
      margin-right: auto;
      margin-left: auto;

      h3 {
        font-weight: normal;
        font-size: 32px;
        margin-bottom: 5px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}


// Mobile
@media (max-width: 1000px) {
  #faq {
    text-align: center;

    .faqItem {
      h3 {
        font-weight: normal;
        font-size: 32px;
        margin-bottom: 5px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}
