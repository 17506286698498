// Desktop
@media (min-width: 1000px) {
  #Location {
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    h2 {
      font-family: "Claudy";
      font-size: 100pt;
      text-align: center;
      font-weight: normal;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    #iframe {
      border: 0;
      width: 100%;
      height: 500px;
    }
  }

}


// Mobile
@media (max-width: 1000px) {
  #Location {
    h2 {
      font-family: "Claudy";
      font-size: 39pt;
      text-align: center;
      font-weight: normal;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    #iframe {
      border: 0;
      width: 100%;
      height: 500px;
    }
  }
}
