// Desktop
@media (min-width: 1000px) {
  #Home {
    text-align: center;
    img {
      width: 80%;
    }

    h2 {
      line-height: 1;
      margin: 32px 0 8px 0;
      font-family: Quicksand;
      font-size: 32px;
      font-weight: normal;
      text-transform: uppercase;
    }

    h3 {
      margin: 8px 0;
      font-family: Quicksand;
      letter-spacing: 3px;
      font-size: 24px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
}


// Mobile
@media (max-width: 1000px) {
  h1 {
    line-height: 1;
    margin: 16px 0;
    font-size: 70pt;
    font-weight: normal;
    letter-spacing: 4px;
    padding-bottom: 30px;
    text-align: center;
  }

  h2 {
    line-height: 1;
    margin: 8px 0;
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }

  img {
    width: 100%;
  }

  #desktopDate {
    display: none;
  }
}